export const GOOGLE_MAP_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || "";

export const RESPONSE_CODE = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const TRAIN_TYPE = {
  SHINKANSEN: 0,
  CONVENTIONAL_LINE: 1,
};

export const LOCATION_TYPE = {
  STATION: 1,
  LANDMARK: 2,
};

export const HANDLING_FEES = {
  EN: 2730,
  KO: 1290,
};

export const FALLBACK_RATES = {
  AUD: 0.010548,
  CNH: 0.050434,
  CNY: 0.050407,
  EUR: 0.0064,
  HKD: 0.055316,
  JPY: 1,
  KRW: 9.430457,
  MYR: 0.03061,
  THB: 0.23632,
  TWD: 0.227176,
  USD: 0.007094,
  VND: 174.15074,
};

export const SEARCH_DEVICE = {
  PC: "japanbullettrain.top.pc",
  SP: "japanbullettrain.top.sp",
};

export const PREFECTURES = {
  1: "aichi",
  2: "akita",
  3: "aomori",
  4: "chiba",
  5: "ehime",
  6: "fukui",
  7: "fukuoka",
  8: "fukushima",
  9: "gifu",
  10: "gunma",
  11: "hiroshima",
  12: "hokkaido",
  13: "hyogo",
  14: "ibaraki",
  15: "ishikawa",
  16: "iwate",
  17: "kagawa",
  18: "kagoshima",
  19: "kanagawa",
  20: "kochi",
  21: "kumamoto",
  22: "kyoto",
  23: "mie",
  24: "miyagi",
  25: "miyazaki",
  26: "nagano",
  27: "nagasaki",
  28: "nara",
  29: "niigata",
  30: "oita",
  31: "okayama",
  32: "okinawa",
  33: "osaka",
  34: "saga",
  35: "saitama",
  36: "shiga",
  37: "shimane",
  38: "shizuoka",
  39: "tochigi",
  40: "tokushima",
  41: "tokyo",
  42: "tottori",
  43: "toyama",
  44: "wakayama",
  45: "yamagata",
  46: "yamaguchi",
  47: "yamanashi",
};

export const CITIES = {
  1: "nagoya",
};

export const AREAS_PER_PREFECTURE: { [key: string]: string[] } = {
  aichi: ["aichi", "nagoya"],
  aomori: ["aomori", "shin_aomori", "hachinohe"],
  chiba: ["chiba"],
  fukui: ["fukui", "tsuruga"],
  fukuoka: ["fukuoka", "hakata"],
  fukushima: ["fukushima", "koriyama"],
  gifu: ["gifu", "takayama"],
  gunma: ["gunma", "takasaki"],
  hiroshima: ["hiroshima"],
  hokkaido: [
    "hakodate",
    "hokkaido",
    "oshamambe",
    "sapporo",
    "shin_hakodate",
    "shin_sapporo",
  ],
  hyogo: ["hyogo", "kinosakionsen"],
  ishikawa: ["ishikawa", "kanazawa"],
  iwate: ["iwate", "morioka"],
  kagoshima: ["kagoshima", "kagoshima_chuo"],
  kanagawa: ["kanagawa", "kawaguchiko", "odawara", "shin_yokohama"],
  kyoto: ["kyoto"],
  miyagi: ["miyagi", "sendai"],
  nagano: ["hakuba", "iiyama", "karuizawa", "matsumoto", "nagano"],
  niigata: ["echigo_yuzawa", "joetsu_myoko", "niigata"],
  okayama: ["okayama"],
  osaka: ["kansai_airport", "osaka", "shin_osaka"],
  saitama: ["omiya", "saitama"],
  shizuoka: ["atami", "mishima", "shin_fuji", "shizuoka"],
  tokyo: ["narita_t1", "shinagawa", "shinjuku", "tokyo", "ueno"],
  toyama: ["toyama"],
  yamanashi: ["yamanashi"],
};

export const SEARCH_TYPE: { [key: string]: string } = {
  ONE_WAY: "one-way",
  ROUND_TRIP: "round-trip",
};
